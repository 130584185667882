<template>
  <v-container
      style="background: rgba(255, 255, 255, .1)" fluid>
    <v-row>
      <v-col>
        <Item>
          <ItemSection side>
            <backArrow
                style="margin-left: 10px;
                margin-top: 13px;
                margin-bottom:15px;
                position: initial;"/>
          </ItemSection>
        </Item>
        <p class="main-title mb-1 pb-2"
           style="margin-left: 25px; margin-right: 25px; margin-top: -13px;">
          {{ article && article.question ? article.question : ' - '}}
        </p>
      </v-col>
    </v-row>
    <v-row
        class='pr-7 pl-7 pb-7'
        style="overflow-y: auto;
        height: calc(100vh - (75px + 98px))">
      <v-col cols="12">
        <span
            v-if="article && article.answer"
            style="white-space: pre-wrap;"
            class="main-text"
            v-html="article.answer"/>
        <span v-else> - </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import backArrow from '../../components/covid/back-arrow';
import {mapMutations} from 'vuex';
import {getArticleById} from '../../services/odoo/KnowledgeService';
import Item from '../../components/structure/item/item'
import ItemSection from '../../components/structure/item/itemSection'

export default {
  name: 'knowledgeArticles',
  components: {
    backArrow,
    Item,
    ItemSection,
  },
  data() {
    return {
      articleId: null,
      article: {}
    }
  },
  methods: {
    ...mapMutations(['showmsg', 'loading']),
    getArticle() {
      this.loading(true);

      getArticleById(this.articleId)
          .then((response) => {
            this.article = response.data.records[0];
          })
          .catch(() => {
            this.showmsg({text: "Erro ao carregar o artigo!", type: "error"});
          }).finally(() => {
        this.loading(false);
      });
    }
  },
  mounted() {
    const {articleId} = this.$route.query;

    this.articleId = articleId;

    this.getArticle();
  }
}
</script>

<style scoped>

</style>